.container-report-area {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
}

.headerblue-report {
  background-color: var(--primay-app-color);
  height: 10vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-data-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}
.area-report-head-div {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 8vh;
  margin-top: 4%;
  align-items: center;
  border: 4px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #a4ce4e, var(--primay-app-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 3px 5px 1px #b1aeae;
}
.area-report-head {
  width: 30%; /* or any other value that fits */
  text-align: center;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  letter-spacing: 0.2px;

  color: #252525;
}

.report-data {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: scroll; */
}
.area-report-data-div {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 10vh;
  /* overflow: scroll; */
  margin-top: 4%;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #a4ce4e, var(--primay-app-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 3px 5px 1px #b1aeae;
}
.area-report-data {
  width: 30%; 
  text-align: center;
}
.area-report-data-label {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 16px; */
  height: 90%;
text-align: center;
  letter-spacing: 0.2px;
  /* align-self: center; */
  color: #252525;
  padding: 10%;
}
.area-report-head-label{
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* line-height: 16px; */
  height: 90%;
text-align: center;
  letter-spacing: 0.2px;
  /* align-self: center; */
  color: #252525;
  padding: 10%;
}

.report-total {
  width: 90%;
  height: 10vh;
  margin-top: 5%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  /* background-color: white; */
  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #a4ce4e, var(--primay-app-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  /* padding: 10px; */
  box-shadow: 2px 3px 5px 1px #b1aeae;
}

.total-amount-pending {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5%;
}

.total-amount-pending-label,
.no-of-unpaid-label {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 144%;
  text-align: center;
  color: #333333;
  margin-left: 10px;
  /* margin-top: */
}

.no-of-transactions-value,
.total-amount-collected-value {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 144%;
  text-align: right;
  color: #34a55a;
  margin-right: 10px;
}

.area-float-div {
  width: 100%;
  height: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
}
