.PaymentContainer {
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.blue-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    height: 10vh;
    overflow-x: hidden;
    /* height: 20vh; */
    background: var(--primay-app-color);
    background-color: var(--primay-app-color);
}

.owner-name-data{
    text-align: left;
    width: 90%;
    overflow-x: scroll !important;
}

.stb_fields {
    text-align: left;
    width: 90%;
    overflow-x: scroll !important;
}

.t-col-1 {
    text-align: left;
    width: 40%;
}
.t-col-2 {
    text-align: center;
    width: 5%;
}
.t-col-3 {
    text-align: left;
    width: 90%;
}
.TableHeader {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.TableHeader tr td {
    color: #252525;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: 1px;
    white-space: nowrap;
    overflow: hidden;
}
.t-row-1 td {
    font-weight: 700 !important;
}
.bodyTable1 {
    position: relative;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
}
.tableContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 5%;
    border: 2px solid transparent;
    border-radius: 15px;
    background-image: linear-gradient(white, white),
        linear-gradient(to bottom right, #a4ce4e, #0090da);
    background-origin: border-box;
    box-shadow: 2px 3px 5px 1px #b1aeae;
    background-clip: content-box, border-box;
}
.top-header {
    background-color: var(--primay-app-color);
    width: 100vw;
    height: 4vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.bodyTable1 tr {
    padding: 5px 0 10px 0;
}

.body-col-1 {
    text-align: left;
    width: 35%;
}
.body-col-2 {
    text-align: center;
    width: 10%;
}
.body-col-3 {
    text-align: left;
    width: 55%;
}
.lStyle {
    /* font-family: "Noto Sans"; */
    /* font-style: normal; */
    font-weight: 500;
    font-size: 15px;
    line-height: 10px;
    letter-spacing: 0.2px;
    color: #2e2a2a;
}

.complaintStatus{
    text-align: left;
    width: 55%;
    font-weight: 700;
    font-size: 13px;
    line-height: 10px;
    letter-spacing: 0.2px;
    color: #0081B3;
}

.multipleOptionBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    margin-top: 25px;
}

.tableContainer input,
select {
    width: 90%;
    margin: 10px;
    padding-bottom: 5px;
    outline: none;
    border: none;
    border-bottom: 2px solid #d9d9d6;
    /* font-family: "Noto Sans"; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.tableContainer input,
select::placeholder {
    color: #333333;
    /* font-family: "Noto Sans"; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.collectbtnPayment {
    margin: 20px;
    width: 90%;
    color: #ffffff;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    background: var(--primay-app-color);
    border-radius: 20px;
    width: 80vw;
    /* width: 224px; */
    height: 48px;
    gap: 8px;
}
.CollapseContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5%;
}
.Collapsible__trigger1 {
    /* display: grid;
  grid-auto-flow: column;
  grid-column-gap: 15vh; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    border-bottom: 2px solid #333333;
    padding-bottom: 10px;
    color: #333333;
}

.Collapsible__trigger1Open {
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #0090da;
    border: 2px solid #0090da;
    border-radius: 15px 15px 0 0;
    border-bottom: hidden;
    transition: 200ms;
}
.Collapsible__trigger2 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    border-bottom: 2px solid #333333;
    padding-bottom: 10px;

    color: #333333;
}
.Collapsible__trigger2Open {
    margin-top: 20px;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    /* border: none; */
    color: #0090da;
    border: 2px solid #0090da;
    border-radius: 15px 15px 0 0;
    border-bottom: hidden;
    transition: 200ms;
}

.Collapsible__trigger2.is-open {
    padding: 20px 10px 20px 10px;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 80px;
    white-space: nowrap;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    border: none;
    color: #0090da;
    border: 2px solid #0090da;
    border-radius: 15px 15px 0 0;
    border-bottom: hidden;
    transition: 400ms;
}

.Collapsible__contentInner {
    /* margin:20px 10px 20px 10px; */

    /* border: 2px solid transparent; */
    border-radius: 0 0 15px 15px;
    border-top: hidden;
    background-image: linear-gradient(white, white),
        linear-gradient(to bottom right,#a4ce4e, #0090da,);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.STBOptionsContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: white;
    border-left: 2px solid #0090da;
    border-bottom: 2px solid #0090da;
    border-right: 2px solid #0090da;
    border-radius: 0px 0px 15px 15px;
}
.STBEachOption {
    width: 75px;
    height: 75px;
    margin: 10%;
    overflow: hidden;
    background-color: #d9d9d9;
    border-radius: 10px;
    box-shadow: 8px 8px 11px 1px #b1aeae;/*  Shadow added */
    border: 2px solid transparent;
    background-image: linear-gradient(white, white),
        linear-gradient(to bottom right, #a4ce4e, #0090da);
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.STBEachOption p {
    text-align: center;
    /* max-width: 50px; */
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    align-self: center;

    color: #333333;
}
.STBEachOption img {
    margin-top: 20%;
}

input[type="date"]:before {
    color: #333333;
    content: attr(placeholder) !important;
    margin-right: 0.5em;
}

input[type="date"]:focus:before {
    content: "" !important;
}
