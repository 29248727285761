.STBHistory-container {
  width: 100%;
  height: 90vh;
  background-color: #e8ebeb;
}

.StaticDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stb-card-div {
  z-index: -1;
  overflow-y: scroll;
  margin-bottom: 2vh;
  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #0090da, #a4ce4e);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.customer-card-div {
  z-index: -1;
  overflow-y: scroll;
  margin-bottom: 2vh;
  width: 90%;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 3px 3px 11px 1px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #0090da, #a4ce4e);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-top: 4%;
}

.stb-div {
  margin-top: 10px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stb-p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  color: #333333;
}

.vcNo-p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  color: #333333;
}

.date-time-p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #252525;
}
