.container-report {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
}

.headerblue-report {
  background-color: #1e90ff;
  height: 15vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-label {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  margin-top: 8%;
  margin-bottom: 8%;
}

.date-report {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
  box-sizing: content-box;
  border: 1px solid #0090da;
  margin-top: 4%;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
}

.from-date {
  position: relative;
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items:flex-start;
  justify-content: center;
}

.from-date-label {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 133%;
  color: #333333;
  margin-bottom: 0;
}

.from-date-input {
  font-family: "MetLife Circular";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 131%;
  color: #333333;
  border: none;
  border-bottom: 2px solid #1e90ff;
  background: transparent;
  font-size: 16px;
  width: 120px;
  margin-left: 10%;
  padding: 2% 0% 0% 0%;
}

.from-date-calender-icon {
  position: absolute;
  float: left;
  color: #1e90ff;
  width: 20px;
  height: 20px;
  margin-top: 18%;
  margin-bottom: 5px;
  margin-left: 165px;
}

.to-date {
  position: relative;
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items: flex-start;
  justify-content: center;
}
.to-date-btn {
  position: relative;
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items: flex-start;
  justify-content: center;
}

.to-date-label {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 133%;
  color: #333333;
  margin-bottom: 0;
}

.to-date-input {
  font-family: "MetLife Circular";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 131%;
  color: #333333;
  border: none;
  border-bottom: 2px solid #1e90ff;
  outline: none;
  background: transparent;
  width: 120px;
  margin-right: 60%;
  padding: 2% 0% 0% 0%;
}

.to-date-calender-icon {
  position: absolute;
  float: left;
  color: #1e90ff;
  width: 20px;
  height: 20px;
  margin-top: 18%;
  margin-bottom: 5px;
  margin-left: 85px;
}

.calender-icon:hover {
  color: #1e90ff;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.get-report-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100px;
  margin-top: 4%;

  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #0090da, #a4ce4e);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.get-report-dropdown {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: -0.24px;
  color: #000000;
  width: 40%;
  margin-left: -30%;
  border: none;
  border-bottom: 1px solid #1e90ff;
  outline: none;
  background: transparent;
  font-size: 16px;
  margin-left: -20%;
}

.get-report-btn {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 136%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 40%;
  height: 70%;
  background-color: #0090da;
  border-radius: 20px;
  border: none;
  outline: none;
  margin-right: -20%;
  text-transform: uppercase;
  margin-left: 10%;
}

.report-data {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: none;
}

/* hide scroll bar */
.report-data::-webkit-scrollbar {
  display: none;
}

.report-data-div {
  /* padding: 10px; */
  width: 90%;
  height: 10vh;
  margin-top: 5%;
  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #a4ce4e, #0090da);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 3px 5px 1px #b1aeae;
}

.report-data-owner {
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
}

.report-data-owner-id {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #252525;
  margin-right: 10px;
  padding-left: 3%;
}

.report-data-owner-amt {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: green;
  margin-left: auto;
  /* padding-right: 2%; */
  padding: 10px;
}

.report-data-owner-name {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #252525;
  padding: 10px;
}

.report-data-payment-mode{
  padding-right: 10px;
  padding-bottom: 2px;
  color: green;
  font-weight: 600;
}

.report-total-card {
  width: 90%;
  height: 10vh;
  margin-top: 5%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  /* background-color: white; */
  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
  linear-gradient(to bottom right, #a4ce4e, #0090da);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 3px 5px 1px #b1aeae;
}

.total-amount-collected {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.total-amount-collected-label,
.no-of-transactions-label {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 144%;
  text-align: center;
  color: #333333;
  margin-left: 10px;
}

.no-of-transactions-value,
.total-amount-collected-value {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 144%;
  text-align: right;
  color: #34a55a;
  margin-right: 10px;
}

.print-report-btn {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 136%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 60%;
  height: 48px;
  background-color: #0090da;
  border-radius: 20px;
  border: none;
  outline: none;
  margin-top: 3%;
}

.float-div {
  width: 100%;
  height: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12%;
}
.inputFocus {
  border: none;
  border-bottom: 2px solid #0090da;
  width: 15vh;
  margin-top: 2px;
}
.inputFocus:focus {
  border-bottom: 2px solid #0090da;
}
.inputFocus::placeholder {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #333333;
}
