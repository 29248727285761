.print-button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 6px;
  margin-top: 10%;
}

.text-input {
  position: relative;
  margin-top: 60%;
  border: 2px solid black;
  border-radius: 5px;
}
