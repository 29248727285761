.container-record-visit {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: #f1f1f1;
}
.record-visit-form {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #a4ce4e, var(--primay-app-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 3px 5px 1px #b1aeae;
  /* column */
  margin-top: 5%;
}
.record-visit-form-row {
  width: 100%;
  margin-left: 8%;
  align-items: left;
  margin-top: 5%;
}

.record-visit-form-input {
  width: 90%;
  height: 40px;
  border: none;
  /* box-sizing: border-box; */
  /* border-radius: 5px; */
  /* margin-top: %; */
  margin-bottom: 5%;
  /* Give border only at the bottom */
  border-bottom: 1px solid #d9d9d6;
}
.record-visit-form-label {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: #333333;
}
input[type="text"]::placeholder {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* or 107% */

  letter-spacing: 0.2px;

  color: #252525;
}
.agent-visit-record-data {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, var(--primay-app-color), #a4ce4e);
  background-origin: border-box;
  background-clip: content-box, border-box;
  /* column */
  margin-top: 5%;
}
table {
  width: 100%;
}
.agent-visit-colon {
  width: fit-content;
}
.agent-visit-label {
  width: 150px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #252525;
}
.agent-visit-value {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #252525;
}

.record-visit-button {
  width: 95%;
  height: 40px;
  border: none;
  border-radius: 25px;
  background-color: var(--primay-app-color);
  color: white;
  font-family: "Noto Sans";
  margin-top: 5%;
}
.visited-history-button {
  width: 95%;
  height: 40px;
  border: none;
  border-radius: 25px;
  background-color: var(--primay-app-color);
  color: white;
  font-family: "Noto Sans";
  margin-top: 5%;
  margin-bottom: 5%;
}
