.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: #ffffff;
  position: fixed !important;
  width: 100%;
  bottom: 0;
  padding: 0px 20px 0px 20px !important;
  box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.08);
}

.navitem {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
}

.nav_img {
  border-radius: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
