.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Background Image */

.bg-img {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.4;
}

/* Top Header Image */

.header-img1 {
  position: relative;
  display: flex;
  width: 95%;
  height: 228px;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  object-fit: contain;
}

/* MobiCable Logo */

.mobicable-logo {
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  width: 80%;
  height: 100px;
  justify-content: center;
  object-fit: contain;
}

/* Login Form */

.authForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Input Group */

.inputGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inputGroup > * {
  margin-top: 5px;
}

/* Username */

.user-label {
  font-family: "Noto Sans", sans-serif;
  font-style: Normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}

.username {
  position: relative;
  width: 100%;
  height: 28px;
  left: 0%;
  right: 1.58%;
  top: 42.86%;
  bottom: 24.49%;
  border: none;
  border-bottom: 1px solid black;
  background: transparent;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.username::placeholder {
  left: 0%;
  right: 1.58%;
  top: 42.86%;
  bottom: 24.49%;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* or 131% */

  color: #333333;
}

/* Password */

.passwd-label {
  position: relative;
  /* margin-top: 10%;
    margin-left: 15%; */
  width: 279px;
  height: 16px;
  font-family: "Noto Sans";
  font-style: Normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}

.passwd {
  position: relative;
  /* margin-left: 15%; */
  width: 100%;
  height: 28px;
  left: 0%;
  right: 1.58%;
  top: 42.86%;
  bottom: 24.49%;
  border: none;
  border-bottom: 1px solid black;
  background: transparent;
  outline: none;

  /* font-family: 'Noto Sans' */
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.passwd::placeholder {
  left: 0%;
  right: 1.58%;
  top: 42.86%;
  bottom: 24.49%;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* or 131% */

  color: #333333;
}

/* Remember me */

.remember-me-div {
  display: flex;
  flex-direction: row;
  /* margin-top: 10%;
    margin-left: 15%; */
}

.rememberme {
  font-family: "Noto Sans", sans-serif;
  display: flex;
  flex-direction: row;
  position: relative;
  color: #333333;
  margin-left: 2%;
}

.rmcb {
  width: 20px;
  height: 20px;
  border: 1.5px solid #333333;
}

/* Login Button */
.login-btn-div {
  align-self: center;
  margin-top: 50px;
}

.loginBtn {
  color: #ffffff;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  gap: 8px;
  width: 224px;
  height: 48px;
  background: var(--primay-app-color);
  border-radius: 20px;
  border: none;
  /* margin-top: 8%; */
  padding: 0;
}

/* Version */

.version {
  font-family: "Noto Sans", sans-serif;
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-top: 3%;

  text-align: center;
  display: flex;
  justify-content: center;
}
