.STBs-Listing-header {
    background-color: var(--primay-app-color);
    width: 100vw;
    height: 15vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
}

.STBs-CardInfo{
    width: 80%;
    overflow: hidden;
}

/*--------------Table 1 design--------------*/
.STBs-Listing-Header-Table{
    width: 90%;
    font-weight: 600;
}

.STBs-Listing-Col-1{
    width: 35%;
    font-weight: 600;
    color: white;
}

.STBs-Listing-Col-2{
    width: 5%;
    font-weight: 600;
    color: white;
}

.STBs-Listing-Col-3{
    width: 60%;
    color: white;
    white-space: nowrap;
    overflow-x: scroll;
}

/*--------------Table 2 design---------------*/
.STBs-Listing-Header-Table2{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 90%;
    height: 100%;
}

.STBs-Listing-Table-tr2{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.STBs-Listing-Col-1-2{
    width: 100px;
    font-weight: 600;
    color: #202122;
    padding: 0px;
    margin: 0px;
}

.STBs-Listing-Col-2-2{
    width: 30px;
    font-weight: 600;
    color: #202122;
    padding: 0px;
    margin: 0px;
}

.STBs-Listing-Col-3-2{
    width: 115px;
    color: #202122;
    white-space: nowrap;
    overflow-x: scroll;
    padding: 0px;
    margin: 0px;
}

.STBs-MainContainer{
 height: 100vh;
 background-color: #f1f1f1;
 overflow-y: scroll;
 padding-top: 16vh;
}

.STBs-CardsContainer{
    padding-top: 15px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.STBs-Cards{
    width: 90%;
    height: 18vh;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid #0090da;
    background-color: white;
    border-radius: 10px;
}

.STBs-CardImg{
    height: 40%;
    width: 20%;
}

.STBs-ImgIcon{
    height: 100%;
    width: 100%;
}