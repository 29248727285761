.edit-customer-container {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f1f1;
}
.edit-customer-form {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  box-shadow: 2px 3px 5px 1px #b1aeae;
  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #a4ce4e,  #0090da);
  background-origin: border-box;
  background-clip: content-box, border-box;
  /* column */
  margin-top: 5%;
}
.edit-customer-form-row {
  width: 100%;
  margin-left: 8%;
  align-items: left;
  margin-top: 5%;
}
.edit-customer-form-label {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  /* identical to box height, or 133% */

  color: #333333;
}
.edit-customer-form-input {
  width: 90%;
  height: 40px;
  border: none;
  /* box-sizing: border-box; */
  /* border-radius: 5px; */
  /* margin-top: %; */
  margin-bottom: 5%;
  /* Give border only at the bottom */
  border-bottom: 1px solid #d9d9d6;
}

input[type="text"]::placeholder {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* or 107% */

  letter-spacing: 0.2px;

  color: #252525;
}
.edit-customer-button {
  width: 95%;
  height: 40px;
  border: none;
  border-radius: 25px;
  background-color: var(--primay-app-color);
  color: white;
  font-family: "Noto Sans";
  margin-top: 40px;
  margin-bottom: 5%;
}
