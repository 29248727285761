.HomeHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1%;
  margin-right: 1%;
  background-color: #0090da;
  width: 98%;
  height: 115px;
}

.HeaderLabel {
  position: relative;
  top: 5%;
  font-family: "Noto Sans", sans-serif;
  padding-left: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.PreviewLabel {
  position: relative;
  margin: 3% 3% 3% 6%;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #333333;
}

.PreviewBackground {
  position: relative;
}

.PreviewBackground:before {
  content: "";
  width: 90%;
  height: 175px;
  left: 5%;
  right: 5%;
  top: 0px;
  opacity: 0.1;
  border-radius: 5px;
  position: absolute;
  background: #007abc;
}

.SelectLangLabel {
  position: relative;
  width: 280px;
  height: 48%;
  left: 55px;
  top: 50px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #333333;
}

.LanguageGroup {
  position: relative;
  width: 280px;
  height: 48px;
  left: 55px;
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.LanguageColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.LanguageColumn div {
  margin-bottom: 20px;
}

.RadioButtons {
  position: relative;
  width: 14px;
  height: 14px;
  background: #0090da;
}

.RadioLabel {
  position: relative;
  width: 67px;
  height: 22px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: inline;
  margin-left: 10px;
  align-items: center;
  letter-spacing: 0.2px;
  color: #333333;
}

.BottomMessageContainer {
  position: relative;
  top: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  height: 115px;
}

.MDInfo {
  position: relative;
  top: 5%;
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #0090da;
}

.BottomMessage {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #333333;
  position: relative;
  top: 5%;
  width: 80%;
}

.SubmitButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 105px;
  gap: 8px;
  border: none;
  position: relative;
  width: 224px;
  height: 48px;
  left: calc(50% - 224px / 2);
  top: 120px;
  margin-bottom: 140px;
  background: #0090da;
  border-radius: 20px;
}

.SubmitButton span {
  width: 95px;
  height: 19px;

  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.StatusBar {
  position: relative;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  border-radius: 10px 10px 0 0;

  height: 25.93px;
  left: 0px;
  right: 0.04px;
  top: 10px;
  background: #ffffff;
}

.SearchBar {
  position: relative;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  height: 25.93px;
  left: 0px;
  right: 0.04px;
  top: 6px;
  background: #ffffff;
}

.GreetingBox {
  position: relative;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  height: 100px;
  left: 0px;
  top: 7px;
  background: linear-gradient(239.99deg, #0090da 0%, #0081b3 100%);
}

.GreetingsLabel {
  position: absolute;
  height: 14px;
  left: 9px;
  right: 148px;
  top: 15px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10.6062px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #ffffff;
}

.GreetingsPara {
  position: absolute;
  height: 33px;
  left: 35px;
  right: 32px;
  top: 40px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #ffffff;
}

.Time {
  position: absolute;
  width: 18.86px;
  height: 10.61px;
  left: 20px;
  top: 15%;
  font-family: "Noto Sans", sans-serif;

  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 1px;

  color: #333333;
}
