
.Container{
  height: 90vh;
  background-color: #f1f1f1;
}
.StaticDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 30vh; */
}
.customer-card-div-history {
  z-index: 1;
  overflow-y: scroll;
  margin-bottom: 1vh;
  width: 90%;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 2px 3px 5px 1px #b1aeae;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #a4ce4e,  #0090da);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-top: 4%;
}

.his-btn {
  color: #ffffff;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  gap: 8px;
  width: 275px;
  height: 48px;
  background: var(--primay-app-color);
  border-radius: 15px;
  border: none;
  margin: 1vh;
}
.hty-btn-hzl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.customer-card-div-history-below {
  z-index: 1;
  overflow-y: scroll;
  margin-bottom: 2vh;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 2px 3px 5px 1px #b1aeae;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #a4ce4e,  #0090da);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.history-map-div1 {
  margin-top: 10px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ScrollingContainer {
  width: 90%;
  height: 100%;
}
.ScrollingContainerParent {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  /* height: 50vh; */
  overflow-x: scroll;
  /* margin-bottom: 5vh; */
}
