.upper-header {
  background-color: var(--primay-app-color);
  width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.upper-header-label {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;

  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
}

video {
  /* width: 80%; */
  border: 1px solid black;
}
