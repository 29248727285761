/* FloatingButton.css */
.floating-button {
    position: fixed;
    bottom: 100px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: 1px #007bff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* opacity: 0; */
    transition: opacity 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .floating-button.visible {
    opacity: 1;
  }
  
  .floating-button.hidden {
    opacity: 1;
  }
  