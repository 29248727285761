.STBHistory-div-container {
  width: 100%;
  height: 90vh;
  background-color: #f1f1f1;
}

.register-complaint-container {
  width: 90%;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 2px 3px 5px 1px #b1aeae;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #a4ce4e, #0090da);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-top: 4%;
}

.register-complaint-form-row {
  width: 100%;
  margin-left: 8%;
  align-items: left;
  margin-top: 5%;
}
.register-complaint-form-label {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  color: #333333;
}
.register-complaint-form-input {
  width: 85%;
  height: 40px;
  border: none;
  background-color: transparent;
  /* box-sizing: border-box; */
  /* border-radius: 5px; */
  /* margin-top: %; */
  margin-bottom: 5%;
  /* Give border only at the bottom */
  border-bottom: 1px solid #d9d9d6;
  margin-left: -1%;
}
input[type="text"]::placeholder {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* or 107% */

  letter-spacing: 0.2px;

  color: #252525;
}

.register-complaint-form-btn {
  width: 95%;
  height: 48px;
  border: none;
  border-radius: 20px;
  background-color: var(--primay-app-color);
  color: white;
  font-family: "Noto Sans";
  margin-top: 15px;
  margin-bottom: 5%;
  margin-left: 2.2%;
}

.customer-card-div-temp {
  z-index: 1;
  overflow-y: scroll;
  margin-bottom: 2vh;
  width: 90%;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 2px 3px 5px 1px #b1aeae;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #a4ce4e, #0090da);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-top: 4%;
}

.DynamicDivLoading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin */
}
