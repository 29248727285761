.container-complaints {
    width: 100%;
    /* height: 90vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    background-color: #e8ebeb;
}
.headerblue-complaints {
    background-color: var(--primay-app-color);
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.complaints-label {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
    margin-top: 8%;
    margin-bottom: 8%;
}

.complaints-id {
    display: flex;
    flex-direction: row;
    white-space: pre-wrap;
    word-break: break-all;
}

.complaint-date {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 3%;
}

.complaint-status {
    width: 1600px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
    display: flex;
    align-items: right;
    text-align: right;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.time-td{
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    padding: 0 !important;
    margin-right: 10%;
}

.complaints-data-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.complaints-data-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 85%;
    /* height : 70px; */
    margin-top: 4%;
    align-items: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid transparent;
    box-shadow: 8px 8px 11px 1px #b1aeae;
    border-radius: 10px;
    background-image: linear-gradient(white, white),
        linear-gradient(to bottom right, #a4ce4e,var(--primay-app-color));
    background-origin: border-box;
    background-clip: content-box, border-box;
    gap: 15px;
}

/* .complaints-data-div {
    display: flex;
    flex-wrap: wrap;
} */

.complaints-data {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
}

.complaints-data-label {
    font-weight: bold;
    /* margin-right: 10px; */
}

.complaints-data:nth-of-type(2) .complaints-data-label {
    flex-basis: 35%;
}

.complaints-data:nth-of-type(3) .complaints-data-label:first-of-type {
    flex-basis: 50%;
}

.complaints-table {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding-bottom: 50px;
}
tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
td {
    color: #000000;
    padding: 1% 1% 0.5% 2.5%;
    width: 100%;
    margin: auto;
}
.complaints-name {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #252525;
    padding-top: 10px;  /* Updated */
}
.Place-complaint {
    width: 2000px;
    padding-bottom: 10px;
}
.alert-line{
    width: 100%;
    height: 1px;
    background: #000000;
    /* margin-top: -1%; */
    margin-bottom: 2%;
}

