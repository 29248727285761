.OptionsContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  height: 32vh;
  align-items: center;
  grid-gap: 10%;
  margin: 7vh;

}

.EachOption {
  width: 100px;
  height: 100px;
  /* margin: 20%; */
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  /* border-image: linear-gradient(to right, #0090da, #a4ce4e); */
  /* border: 2px solid transparent; */
  box-shadow: 8px 8px 11px 1px #b1aeae;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #0090da, #a4ce4e);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.EachOption p {
  width: 50px;
  text-align: center;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;

  color: #333333;
}
.EachOption img {
  margin-top: 20%;
}
