.AddChannelContain {
  width: 100%;
  /* overflow: hidden; */
  height: 100vh;
  position: relative;
}
.addChannelMainContainer {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
  background-color: #f1f1f1;
}
.SearchAndDropDownAaddChannel {
  width: 90%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid transparent;
  background-image: linear-gradient(white, white), linear-gradient(to bottom right, #0090da, #a4ce4e);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 15px;
  /* padding: 2%; */
}
.SearchBarAddChannel {
  /* width: 20px; */
  flex: 1;
  padding-left: 5px;
}
.DropDownAddChannel {
  margin-left: 8px;
  width: 40%;
  padding-right: 5px;
}
#SearchChannel {
  width: 70%;
  border: none;
  border-bottom: 1px solid #0090da;
}
.DropChannel {
  padding-right: 2%;
  border-bottom: 1px solid #0090da;
  background-color: white;
}
.AddChannelDataDiv {
  width: 100%;
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
/* 
.AddChannelData {
  height: 70vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.SingleChannelData {
  width: 95%;
  height: 10vh;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 25px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #0090da, #a4ce4e);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-sizing: border-box; /* Include padding and border in total width and height */
/* } */

/* .channel {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3%;
}  */
.FloatingButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 50px;
  background-color: #0090da;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}

.FloatingButton:hover {
  background-color: #a4ce4e;
}
.AddChannelData {
  height: 65vh;
  width: 95%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}
.SingleChannelData {
  min-height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-top: 10px;
  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white), linear-gradient(10deg, #0090da 70%, #a4ce4e);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 2px 5px 1px #b1aeae;
  position: relative;
}

.channel {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3%;
  width: 25%;
}
#channelName {
  width: 200px;
}

.spacer {
  width: 50%;
}
.floating-AddChannel-div {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin-bottom: 12%; */
}

.AddChannelButton {
  width: 90%;
  height: 40px;
  border-radius: 25px;
  /* background-color: #0090da; */
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  border: none;
  /* text as caps */
  text-transform: uppercase;
}
.legend {
  width: max-content;
  font-size: 12px;
  background-color: white;
  padding: 0 10px;
  position: absolute;
  top: -12px;
  left: 70%;
  transform: translateX(-50%);
}

.SingleChannelData:before,
.SingleChannelData:after {
  content: "";
  position: absolute;
  width: 40%;
  top: -6px;
  z-index: 1;
}

.SingleChannelData:before {
  left: 0;
}

.SingleChannelData:after {
  right: 0;
}
