.selectedPackMainPack {
  width: 100%;
  /* overflow: hidden; */
  height: 100vh;
  position: relative;
  overflow-y: hidden;
}
.selectedPackMain {
  margin-top: 5px;
  width: 100%;
  height: 55vh;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  position: relative;
}
.selectedPackMainData {
  width: 95%;
  height: 55vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.selectedPackMainFooter {
  margin-top: 8px;
  width: 100%;
  height: 35vh;
  /* display: flex;
  justify-content: center; */
}
.SelecetedPacLine {
  width: 100%;
  height: 1px;
  background-color: #000000;
  margin-top: 10px;
}
.packselectedtable {
  width: 95%;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #0090da, #a4ce4e);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin: 0 auto;
  margin-top: 8px;
}
.selectedButtonPackdiv {
  width: 100%;
  /* height: 10vh; */
  /* space between two button */
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
}
